import React from "react";

/**
 * InputLabel component
 * @param {string} forInput
 * @param {string} text
 * @param {string} className
 * @param {JSX.Element} children
 * @returns {JSX.Element}
 * @constructor
 */
export default function InputLabel({ forInput, text, className, children }) {
	return (
		<label
			htmlFor={forInput}
			className={`block font-bold mb-1 text-sm font-sans ${className}`}
		>
			{text ? text : children}
		</label>
	);
}
